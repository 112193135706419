
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromTasks from "../actions/tasks.action";
import { Task } from "../../models/task";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Project } from "../../models/project";
import { Priority } from "../../models/priority";
import { User, AuthUser, TeamUser } from "../../models/user";
import { StatisticsInfo, Tag, TaskStatistics, List, SearchQuery, Location, Settings } from "../../models/index";

export interface TaskState extends EntityState<Task> {
  isLoaded: boolean;
  isLoading: boolean;
  // Pagination Related.
  isNextPageLoading: boolean;
  isNextPageLoaded: boolean;
  currentOffset: number;
  isMoreTasks: boolean;

  sortBy: string;
  isSearchMode: boolean;
  selectedFilterType: string;
  selectedFilterOption: string;

  projectList: Project[];
  priorityList: Priority[];
  memberList: User[];
  tagList: Tag[];
  folderList: List[];
  saveSearchesList: SearchQuery[];
  locationList: Location[];

  authUser: AuthUser;
  taskStatisticInfo: TaskStatistics[];
  searchStatisticInfo: StatisticsInfo;
  tagStatisitcInfo: StatisticsInfo;
  listStatisitcInfo: StatisticsInfo;
  locationStatisitcInfo: StatisticsInfo;

  selectedTasksIds: number[];
  selectAll: boolean;
  isDetailView: boolean;
  isMultiSelectView: boolean;
  isEditTaskView: boolean;
  isOpenTasksChecked: boolean;
  isCloseTasksChecked: boolean;
  isShowMyTaskChecked: boolean;
  reminderTasks: any[];
  assignmentTasks: any[];
  myTeamUsers: TeamUser[];
  isTaskDetailHighlight: boolean;
  settings: Settings;
}

export const taskAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: (task: Task) => task.id
});

export const initialState: TaskState = taskAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  isNextPageLoading: false,
  isNextPageLoaded: false,
  currentOffset: 0,
  isMoreTasks: false,

  sortBy: "due_date",
  isSearchMode: false,
  selectedFilterType: null,
  selectedFilterOption: null,

  projectList: [],
  priorityList: [],
  memberList: [],
  tagList: [],
  folderList: [],
  authUser: null,
  taskStatisticInfo: null,
  searchStatisticInfo: { total_count: 0, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0},
  selectedTasksIds: [],
  selectAll: false,
  isDetailView: false,
  tagStatisitcInfo: { total_count: 0, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0},
  listStatisitcInfo: { total_count: 0, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0},
  locationStatisitcInfo: { total_count: 0, due_tomorrow_count: 0, overdue_count: 0, completed_count: 0},
  isMultiSelectView: false,
  isEditTaskView: false,
  isOpenTasksChecked: false,
  isCloseTasksChecked: false,
  isShowMyTaskChecked: false,
  reminderTasks: [],
  assignmentTasks: [],
  myTeamUsers: [],
  saveSearchesList: [],
  locationList: [],
  isTaskDetailHighlight: false,
  settings: null
});

export function taskReducer(
  state = initialState,
  action: fromTasks.TasksAction
): TaskState {
  switch (action.type) {

    case fromTasks.LOAD_TASKS:
    case fromTasks.UPDATE_TASK:
    case fromTasks.CREATE_TASK:
    case fromTasks.REMOVE_TASKS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromTasks.LOAD_TASKS_SUCCESS: {
      return taskAdapter.addAll(action.payload.task, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreTasks: action.payload.isMoreTasks
      });
    }

    case fromTasks.LOAD_TASKS_FAIL:
    case fromTasks.UPDATE_TASK_FAIL:
    case fromTasks.CREATE_TASK_FAIL:
    case fromTasks.REMOVE_TASKS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case fromTasks.NEXT_LOAD_TASKS: {
      return {
        ...state,
        isLoading: true,
        isNextPageLoading: true
      };
    }

    case fromTasks.NEXT_LOAD_TASKS_SUCCESS: {
      const newState = taskAdapter.addMany(action.payload.task, {
        ...state,
        isNextPageLoading: false,
        isNextPageLoaded: true,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreTasks: action.payload.isMoreTasks
      });

      const changes = action.payload.task.map(task => {
        return {id: task.id, changes: task };
      });
      return taskAdapter.updateMany(changes, newState);
    }

    case fromTasks.NEXT_LOAD_TASKS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isNextPageLoading: false,
        isNextPageLoaded: false,
      };
    }

    case fromTasks.ALLOW_LOAD_MORE: {
      return {
        ...state,
        isMoreTasks: true
      };
    }

    case fromTasks.UPDATE_TASK_SUCCESS: {
      return taskAdapter.updateOne(action.payload.task, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case fromTasks.UPDATE_TASKS_SUCCESS: {
      return taskAdapter.updateMany(action.payload.tasks, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case fromTasks.CREATE_TASK_SUCCESS: {
      return taskAdapter.addOne(action.payload.task, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case fromTasks.CREATE_TASKS_SUCCESS: {
      return taskAdapter.addMany(action.payload.tasks, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case fromTasks.REMOVE_TASKS_SUCCESS: {
      return taskAdapter.removeMany(action.payload.ids, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: state.currentOffset - action.payload.ids.length
      });
    }

    case fromTasks.SEARCH_MODE_TOGGLE: {
      return {
        ...state,
        isSearchMode: action.payload
      };
    }

    case fromTasks.SET_TASK_FILTER_OPTION: {
      return {
        ...state,
        selectedFilterOption: action.payload
      };
    }

    case fromTasks.SET_TASK_FILTER_TYPE: {
      return {
        ...state,
        selectedFilterType: action.payload
      };
    }

    case fromTasks.SET_SORT_BY: {
      return {
        ...state,
        sortBy: action.payload
      };
    }

    case fromTasks.SET_SEARCH_TASK_STATISTICS_INFO: {
      return {
        ...state,
        searchStatisticInfo: action.payload
      };
    }

    case fromTasks.SET_TASK_STATISTICS_INFO: {
      return {
        ...state,
        taskStatisticInfo: action.payload
      };
    }

    case fromTasks.SET_TAG_STATISTICS_INFO: {
      return {
        ...state,
        tagStatisitcInfo: action.payload
      };
    }

    case fromTasks.SET_LIST_STATISTICS_INFO: {
      return {
        ...state,
        listStatisitcInfo: action.payload
      };
    }

    case fromTasks.SET_LOCATION_STATISTICS_INFO: {
      return {
        ...state,
        locationStatisitcInfo: action.payload
      };
    }

    case fromTasks.STORE_PROJECT_LIST: {
      return {
        ...state,
        projectList: action.payload
      };
    }

    case fromTasks.STORE_TAGS_LIST: {
      return {
        ...state,
        tagList: action.payload
      };
    }

    case fromTasks.STORE_FOLDER_LIST: {
      return {
        ...state,
        folderList: action.payload
      };
    }

    case fromTasks.STORE_LOCATION_LIST: {
      return {
        ...state,
        locationList: action.payload
      };
    }

    case fromTasks.STORE_PRIORITY_LIST: {
      return {
        ...state,
        priorityList: action.payload
      };
    }

    case fromTasks.STORE_MEMBER_LIST: {
      return {
        ...state,
        memberList: action.payload
      };
    }

    case fromTasks.STORE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload
      };
    }

    case fromTasks.SET_TASK_ISLOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }

    case fromTasks.ADD_SELECTED_TASKS_ID: {
      let selectedTaskIds = state.selectedTasksIds;
      selectedTaskIds.push(action.payload);
      return {
        ...state,
        selectedTasksIds: [...selectedTaskIds]
      };
    }

    case fromTasks.ADD_SELECTED_TASKS_IDS: {
      let selectedTaskIds = state.selectedTasksIds.concat(action.payload);
      return {
        ...state,
        selectedTasksIds: [...selectedTaskIds]
      };
    }

    case fromTasks.REMOVE_SELECTED_TASKS_ID: {
      let selectedTaskIds = state.selectedTasksIds;
      selectedTaskIds.splice(selectedTaskIds.indexOf(action.payload), 1);
      return {
        ...state,
        selectedTasksIds: [...selectedTaskIds]
      };
    }

    case fromTasks.UPDATE_REMINDER_TASKS: {
      let reminderTasks = state.reminderTasks;
      reminderTasks = [...reminderTasks, ...action.payload];
      return {
        ...state,
        reminderTasks: reminderTasks
      };
    }

    case fromTasks.UPDATE_ASSIGNMENT_TASKS: {
      let assignmentTasks = state.assignmentTasks;
      assignmentTasks = [...assignmentTasks, ...action.payload];
      return {
        ...state,
        assignmentTasks: assignmentTasks
      };
    }

    case fromTasks.RESET_SELECTED_TASKS_ID: {
      return {
        ...state,
        selectedTasksIds: []
      };
    }

    case fromTasks.SET_SELECT_ALL: {
      return {
        ...state,
        selectAll: action.payload
      };
    }

    case fromTasks.SET_DETAIL_VIEW: {
      return {
        ...state,
        isDetailView: action.payload
      };
    }

    case fromTasks.SET_MULTI_SELECT_TASK_VIEW: {
      return {
        ...state,
        isMultiSelectView: action.payload
      };
    }

    case fromTasks.SET_EDIT_TASK_VIEW: {
      return {
        ...state,
        isEditTaskView: action.payload
      };
    }

    case fromTasks.SET_OPEN_TASKS_CHECKED: {
      return {
        ...state,
        isOpenTasksChecked: action.payload
      };
    }

    case fromTasks.SET_OPEN_TASKS_CHECKED: {
      return {
        ...state,
        isOpenTasksChecked: action.payload
      };
    }

    case fromTasks.SET_CLOSE_TASKS_CHECKED: {
      return {
        ...state,
        isCloseTasksChecked: action.payload
      };
    }

    case fromTasks.SET_SHOW_MY_TASK_CHECKED: {
      return {
        ...state,
        isShowMyTaskChecked: action.payload
      };
    }

    case fromTasks.STORE_MY_TEAM_USER: {
      return {
        ...state,
        myTeamUsers: action.payload
      };
    }

    case fromTasks.STORE_SAVE_SEARCHES_LIST: {
      return {
        ...state,
        saveSearchesList: action.payload
      };
    }

    case fromTasks.SET_TASK_DETAIL_HIGHLIGHT: {
      return {
        ...state,
        isTaskDetailHighlight: action.payload
      };
    }

    case fromTasks.STORE_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      };
    }
  }

  return state;
}

export const _getIsLoading = (state: TaskState) => state.isLoading;
export const _getIsLoaded = (state: TaskState) => state.isLoaded;
export const _getSortBy = (state: TaskState) => state.sortBy;
export const _getIsMoreTasks = (state: TaskState) => state.isMoreTasks;
export const _getCurrentOffset = (state: TaskState) => state.currentOffset;
export const _getIsNextPageLoading = (state: TaskState) => state.isNextPageLoading;
export const _getIsNextPageLoaded = (state: TaskState) => state.isNextPageLoaded;
export const _getIsSearchMode = (state: TaskState) => state.isSearchMode;
export const _getSelectedFilterOption = (state: TaskState) => state.selectedFilterOption;
export const _getSelectedFilterType = (state: TaskState) => state.selectedFilterType;
export const _getProjectsList = (state: TaskState) => state.projectList;
export const _getPriorityList = (state: TaskState) => state.priorityList;
export const _getTagList = (state: TaskState) => state.tagList;
export const _getMemberList = (state: TaskState) => state.memberList;
export const _getAuthUser = (state: TaskState) => state?.authUser;
export const _getSearchStatisticsInfo = (state: TaskState) => state.searchStatisticInfo;
export const _getTaskStatisticsInfo = (state: TaskState) => state.taskStatisticInfo;
export const _getSelectedTasksIds = (state: TaskState) => state.selectedTasksIds;
export const _getSelectAll = (state: TaskState) => state.selectAll;
export const _getIsDetailView = (state: TaskState) => state.isDetailView;
export const _getTagStatisticsInfo = (state: TaskState) => state.tagStatisitcInfo;
export const _getIsMultiSelectView = (state: TaskState) => state.isMultiSelectView;
export const _getIsEditTaskView = (state: TaskState) => state.isEditTaskView;
export const _getIsOpenTasksChecked = (state: TaskState) => state.isOpenTasksChecked;
export const _getReminderTasks = (state: TaskState) => state.reminderTasks;
export const _getFolderList = (state: TaskState) => state.folderList;
export const _getListStatisticsInfo = (state: TaskState) => state.listStatisitcInfo;
export const _getMyTeamUsers = (state: TaskState) => state.myTeamUsers;
export const _getSaveSearches = (state: TaskState) => state.saveSearchesList;
export const _getLocationList = (state: TaskState) => state.locationList;
export const _getLocationStatisticsInfo = (state: TaskState) => state.locationStatisitcInfo;
export const _getIsShowMyTaskChecked = (state: TaskState) => state.isShowMyTaskChecked;
export const _getAssignmentTasks = (state: TaskState) => state.assignmentTasks;
export const _getIsTaskDetailHighlight = (state: TaskState) => state.isTaskDetailHighlight;
export const _getIsCloseTasksChecked = (state: TaskState) => state.isCloseTasksChecked;
export const _getSettings = (state: TaskState) => state.settings;