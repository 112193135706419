
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, NgZone } from "@angular/core";
import { MdlDialogService, MdlSnackbarService } from "@angular-mdl/core";
import { TaskComposeComponent } from "../task-compose/task-compose.component";
import { Task, TaskComment, BulkUpdateArgs, AuthUser, TaskStatistics, StatisticsInfo } from "../../models";
import { TaskService } from "../../task.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TaskReminderComponent } from "../task-reminder/reminder-dialog.component";
import { TaskHeaderMenubarComponent } from "../task-header-menubar/task-header-menubar.component";
import { TaskOperation } from "../../shared/task-enum";
import { TaskBulkEditDialogComponent } from "../task-bulk-edit-dialog/task-bulk-edit-dialog.component";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { BulkUpdateIssueType, SuccessType, ErrorType } from "../../shared/task-enum";
import { DatePipe } from "@angular/common";
import { MdlPopoverComponent } from "@angular-mdl/popover";
import { Store } from "@ngrx/store";
import { TaskRepository } from "../../repository/task.repository";
import { TasksRootState, getTasksIsLoading, getTasks, getIsTasksIsMoreTasks, getIsTasksNextPageLoading, getIsSearchMode, SetTaskFilterOption, SearchModeToggle, SetSortBy, getAuthUser, SetIsLoading, getSortBy, StoreAuthUser, UpdateTasksSuccess, getSelectedFilterOption, SetTaskFilterType, getTagList, getSelectedFilterType, getTaskStatisticsInfo, getSearchStatisticsInfo, getSelectAll, getSelectedTasksIds, ResetSelectedTaskId, AddSelectedTaskIds, SetSelectAll, AddSelectedTaskId, RemoveSelectedTaskId, getIsDetailView, SetDetailView, getIsMultiSelectView, SetMultiSelectTaskView, SetEditTaskView, SetOpenTasksChecked, getIsOpenTasksChecked, getFolderList, SetShowMyTaskChecked, getIsShowMyTaskChecked, SetTaskDetailHighlight, getIsCloseTasksChecked, SetCloseTasksChecked, getLocationList, LoadTasksSuccess } from "../../store/index";
import { TasksConstants } from "../../shared/task-constacts";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { ConfigService } from "../../../common/providers/config.service";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { ResponsiveService } from "../../../common/providers/responsive.service";
import { TaskUtils } from "../../shared/task-utils";
import sort from "fast-sort";
import { HeaderService } from "../../services/header.service";
import { SearchRepository } from "../../repository/search.repository";
import { dialogType } from "../../models";
import { MessageTranslatorService } from "../../services";
import { CommonUtil } from "../../../common/utils/common.utils";
import { DatabaseService } from "../../services/database.service";
import { environment } from "../../../../environments/environment";
import { getIsRightSidebarExpanded, IsDatabaseReady } from "../../../reducers";
import { getOnlineStatus, getLoginStatus } from "../../../reducers";
import { AppConstants } from "../../../common/utils/app-constants";
import { SaveQueryDialogComponent } from "../save-query-dialog/save-query-dialog.component";
import PullToRefresh from "pulltorefreshjs";
import { TaskWatchersComponent } from "../task-watchers-dialog/task-watchers-dialog.component";
import { takeWhile, take, filter } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { VNCActionWheelMenuService } from "vnc-library";

@Component({
  selector: "vp-task-main-layout-component",
  templateUrl: "task-main-layout.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskMainLayoutComponent implements OnInit, OnDestroy {
  isAlive = true;

  tasks: Task[];
  selectedTasks: any[] = [];
  selectedTasksIds: number[] = [];

  filters: any = null;
  @ViewChild("filter", { static: false }) filter: TaskHeaderMenubarComponent;
  @ViewChild(TaskHeaderMenubarComponent, { static: false }) headerMenubar: TaskHeaderMenubarComponent;
  @ViewChild("taskContextPopover", { static: false }) contextPopover: MdlPopoverComponent;
  selectAll: boolean = false;
  isChangeUserOptionVisible: boolean = true;
  datePipe: DatePipe = new DatePipe("en");
  isMoreTasks: boolean = false;
  isNextPageLoading: boolean = false;
  isLoading: boolean = false;
  isSearchMode: boolean = false;
  authUser: AuthUser;
  moreTaskLimit: number = 10;
  routingOption: any;
  routingType: any;
  total_count: number = 0;
  preSelectedTask: Task = null;
  isSearchDialogActive = false;
  screen: string = this.responsiveService.getScreen();
  dialogType = dialogType;
  isSaveSearchEnable = false;
  selectedFilterType: string;
  taskConstants = TasksConstants;
  searchStatistic: StatisticsInfo = {
    total_count: 0,
    due_tomorrow_count: 0,
    overdue_count: 0,
    completed_count: 0
  };
  isMobileDevice = CommonUtil.isOnMobileDevice();
  isDetailView = false;
  isMutiSelectView = false;
  isOpenTasksChecked = false;
  isCloseTasksChecked = false;
  isShowMyTaskChecked = false;
  isOnline: boolean = false;
  isLoggedIn: boolean = false;
  isRefreshing: boolean = false;
  previousScrollTopBeforeLoadMore = 0;
  loadMoreLimit = 10;
  previousScrollTop = 0;
  totalCloseTasks: number = 0;
  totalOpenTasks: number = 0;
  totalMyTasks: number = 0;
  loadFilterTaskLimit = 5;
  rightSidebarExpanded = false;

  constructor(
    private service: TaskService,
    private dialogService: MdlDialogService,
    private router: Router,
    private activated: ActivatedRoute,
    private mdlSnackbarService: MdlSnackbarService,
    private translate: TranslateService,
    private store: Store<TasksRootState>,
    private tasksRepo: TaskRepository,
    private searchRepo: SearchRepository,
    private errorService: ErrorService,
    private successService: SuccessService,
    private configService: ConfigService,
    private broadcaster: Broadcaster,
    private headerService: HeaderService,
    private responsiveService: ResponsiveService,
    private changerDetectorRef: ChangeDetectorRef,
    private databaseService: DatabaseService,
    private messageTranslatorService: MessageTranslatorService,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    private actionWheelMenuService: VNCActionWheelMenuService
  ) {
    this.setupStore();
  }

  ngOnInit() {
    if (CommonUtil.isOnNativeMobileDevice()) {
      document.addEventListener("deviceready", this.onDeviceReady.bind(this));
    } else {
      this.initialSetup();
    }
  }

  onDeviceReady() {
    this.initialSetup();
  }

  private initialSetup() {
    // console.log('[task.main.layout.component] initialSetup');

    this.databaseService.fireDatabaseReadyIfInitialized();

    this.handleErrorMessages();
    this.handleSuccessMessages();

    this.handleRemoveTaskSuccessMessages();
    this.handleRemoveTasksSuccessMessages();
    this.handleCompleteTaskSuccessMessages();
    this.handleDuplicateTaskSuccessMessages();
    this.handleStatusUpdateTaskSuccessMessages();
    this.handleWatcherUpdateTaskSuccessMessages();
    this.bindTaskSortEvents();

    this.activated.data.pipe(takeWhile(() => this.isAlive)).subscribe(data => {
      if (data.type) {
        console.log("[task.main.layout.component] route type: ", data.type);
        this.store.dispatch(new SetTaskFilterType(data.type));
      }
    });

    // Handle route changes (tasks list, search, tags)
    this.activated.params.pipe(takeWhile(() => this.isAlive)).subscribe(params => {
      console.log("[task.main.layout.component] route filter: ", params.filter);
      if (params.filter) {
        this.store.dispatch(new SetTaskFilterOption(params.filter));
        this.store.dispatch(new SetDetailView(false));

        // tasks search
        if (params.filter === TasksConstants.ROUTE_SEARCH) {
          this.store.dispatch(new SearchModeToggle(true));
          this.headerService.showSearchDialog$.next(true);
          this.retrieveTasksAndStats(params.filter);

        // tasks list
        } else {
          this.store.dispatch(new SearchModeToggle(false));
          this.headerService.showSearchDialog$.next(false);


          // Mobile devices
          if (CommonUtil.isSQLSupported()) {
            this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
              this.store.select(getAuthUser).pipe(filter(aUser => !!aUser), take(1)).subscribe(aUser => {
                this.retrieveTasksAndStats(params.filter);

              });
            });
          // Web client
          } else {
            // console.log('[task.main.layout.component] web');
            this.store.select(getAuthUser).pipe(filter(aUser => !!aUser), take(1)).subscribe(aUser => {
              // console.log('[task.main.layout.component] aUser', aUser);
              this.retrieveTasksAndStats(params.filter);
            });
          }
        }

        this.resetTasks();
      }
    });

    this.activated.queryParams.pipe(takeWhile(() => this.isAlive)).subscribe(queryParams => {
      console.log("[task.main.layout.component] route queryParams:::", queryParams);
      if (queryParams) {
        if (queryParams.action) {
          if (queryParams && queryParams.action === "compose" && queryParams.assignee) {
            setTimeout(() => {
              this.openAddTaskDialog(queryParams.assignee);
            }, 2000);
          }
          if (queryParams && queryParams.action === "compose" && (queryParams.description !== undefined)) {
            setTimeout(() => {
              const data = localStorage.getItem("shared_data");
              let desc = queryParams.description;
              if (data) {
                const intents = JSON.parse(data);
                desc = intents.intent.text;
                desc = desc.replace(/(?:\r\n|\r|\n)/g, "<br>");
                localStorage.removeItem("shared_data");
              }
              this.openTaskComposewithDescDialog(desc);
            }, 1000);
          }
        }
      }
    });

    const currentObject = this;
    const ptr = PullToRefresh.init({
      mainElement: "#taskListLayout",
      triggerElement: "#taskListLayout",
      instructionsPullToRefresh: " ",
      instructionsReleaseToRefresh: " ",
      instructionsRefreshing: " ",
      distThreshold: 10,
      distReload: 20,
      onRefresh() {
        currentObject.pullToRefresh();
      }
    });
  }

  pullToRefresh(): void {
    this.isRefreshing = true;
    this.changerDetectorRef.markForCheck();
    this.tasksRepo.reloadApp();
  }

  private retrieveTasksAndStats(paramsFilter?) {
    console.log("[task.main.layout.component] retrieveTasksAndStats");
    let routingType;
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
    if (routingType === TasksConstants.ROUTE_TYPE_LIST) {
      this.store.select(getFolderList).pipe(filter(lists => lists.length > 0), take(1)).subscribe(lists => {
        console.log("[task.main.layout.component] retrieveTasksAndStats", lists);
        if (this.isOnline) this.tasksRepo.getTasks();
      });
    } else if (routingType === TasksConstants.ROUTE_TYPE_TAG) {
      this.store.select(getTagList).pipe(filter(tags => tags.length > 0), take(1)).subscribe(tags => {
        if (this.isOnline) this.tasksRepo.getTasks();
      });
    } else  if (routingType === TasksConstants.ROUTE_TYPE_LOCATION) {
      this.store.select(getLocationList).pipe(filter(locations => locations.length > 0), take(1)).subscribe(locations => {
        if (this.isOnline) this.tasksRepo.getTasks();
      });
    }
    if (this.isOnline) {
      this.tasksRepo.getTasks();
      this.tasksRepo.getTasksStats();
    }
    else {
      this.tasksRepo.getDataFromDatabase(paramsFilter);
      this.tasksRepo.getTasksStats();
    }
  }

  bindTaskSortEvents(): void {
    this.broadcaster.on<any>("sortTasksList").pipe(takeWhile(() => this.isAlive))
    .subscribe(presence => {
      this.sortTasks();
    });
  }

  private sortTasks() {
    let sortKey;
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortKey = o);
    console.log("[task.main.layout.component] sortTasks sortKey: ", sortKey);

    // Priority
    if (sortKey === TasksConstants.PRIORITY_SORTING_KEY ) {
      sort(this.tasks).desc( task => task.priority.id);

    // Subject
    } else if (sortKey === TasksConstants.TASK_NAME_SORTING_KEY){
      sort(this.tasks).asc( task => task.subject.toUpperCase());

    // Start date
    } else if (sortKey === TasksConstants.START_DATE_SORTING_KEY){
      sort(this.tasks).asc( task => {
        if (task.start_date) {
          return task.start_date;
        } else {
          return TaskUtils.dueDateOrStartDateNull();
        }
      });

    // Status
    } else if (sortKey === TasksConstants.STATUS_SORTING_KEY){
      sort(this.tasks).asc( task => {
        if (task.status.name === "New" ) {
          return -1;
        } else if ( task.status.name === "Completed" ) {
          return 1;
        } else {
          return 0;
        }
      });
    } else if (sortKey === TasksConstants.CREATED_SORTING_KEY) {
      sort(this.tasks).desc( task => task.created_on);
    // Due date
    } else {
      sort(this.tasks).asc( task => {
        if (task.due_date) {
          return task.due_date;
        } else {
          return TaskUtils.dueDateOrStartDateNull();
        }
      });
    }
  }

  handleErrorMessages() {
    console.log("[task.main.layout.component] handleErrorMessages");

    this.errorService.only(ErrorType.GenericMessage).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.mdlSnackbarService.showToast(
        error.messages
      );
      this.store.dispatch(new SetIsLoading(false));
      this.onPushBugHack();
    });
  }

  handleSuccessMessages() {
    console.log("[task.main.layout.component] handleSuccessMessages");

    this.successService.only(SuccessType.GenericMessage).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        success.messages
      );
      this.store.dispatch(new SetIsLoading(false));
      this.onPushBugHack();
    });
  }

  handleRemoveTaskSuccessMessages() {
    this.successService.only(SuccessType.TaskRemoved).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      console.log("[task.main.layout.component] handleRemoveTaskSuccessMessages");

      this.mdlSnackbarService.showToast(
        success.messages
      );

      // update counters
      this.tasksRepo.getTasksStats();
      this.tasksRepo.getTagsListWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
        this.tasksRepo.getFolderListWithCounters();
      }

      if (this.isDetailView) {
        this.store.dispatch(new SetDetailView(false));
        this.router.navigate(["./"], { relativeTo: this.activated });
      }
      this.onPushBugHack();
    });
  }

  handleRemoveTasksSuccessMessages() {
    this.successService.only(SuccessType.TasksRemoved).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      console.log("[task.main.layout.component] handleRemoveTasksSuccessMessages");

      this.mdlSnackbarService.showToast(
        success.messages
      );
      this.clearAll(true);

      // update counters
      this.tasksRepo.getTasksStats();
      this.tasksRepo.getTagsListWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
        this.tasksRepo.getFolderListWithCounters();
      }
      this.onPushBugHack();
    });
  }

  handleCompleteTaskSuccessMessages() {
    this.successService.only(SuccessType.TaskCompleted).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      console.log("[task.main.layout.component] handleCompleteTaskSuccessMessages");

      this.mdlSnackbarService.showToast(
        success.messages
      );

      // update counters
      this.tasksRepo.getTasksStats();
      this.onPushBugHack();
      // if (this.isDetailView && this.routingOption === this.taskConstants.ROUTE_OPEN) {
        // this.store.dispatch(new SetDetailView(false));
        // this.router.navigate(["./"], { relativeTo: this.activated });
      // }
    });
  }

  handleDuplicateTaskSuccessMessages() {
    this.successService.only(SuccessType.TaskDuplicated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        success.messages
      );
      if (CommonUtil.isOnMobileDevice()) {
        if (!this.isDetailView) {
          this.clearAll(true);
        }
      } else {
        this.clearAll(true);
      }

      // update counters
      this.tasksRepo.getTasksStats();
      this.tasksRepo.getTagsListWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
        this.tasksRepo.getFolderListWithCounters();
      }
      this.onPushBugHack();
    });
  }

  handleStatusUpdateTaskSuccessMessages() {
    this.successService.only(SuccessType.TaskBulkStatusUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        success.messages
      );
      this.clearAll(true);
      this.tasksRepo.getTasksStats();
      this.onPushBugHack();
    });
  }

  handleWatcherUpdateTaskSuccessMessages() {
    this.successService.only(SuccessType.TaskBulkWatcherUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        success.messages
      );
      this.clearAll(true);
      this.tasksRepo.getTasksStats();
      this.onPushBugHack();
    });
  }

  setupStore() {
    this.previousScrollTopBeforeLoadMore = 0;

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getLoginStatus).pipe(takeWhile(() => this.isAlive)).subscribe( isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsTasksIsMoreTasks).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("[task.main.layout.component] getIsTasksIsMoreTasks, isMoreTasks: ", value);
      this.isMoreTasks = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsTasksNextPageLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) =>  {
      console.log("[task.main.layout.component] getIsTasksNextPageLoading, isNextPageLoading: ", value);
      this.isNextPageLoading = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getSelectedFilterType).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.routingType = value;
      this.changerDetectorRef.markForCheck();
    });

    // on tasks all/open/completed... value changed
    this.store.select(getSelectedFilterOption).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.routingOption = value;
      if (this.routingType) {
        if (this.routingType === TasksConstants.ROUTE_TYPE_STATUS){
          if (this.routingOption !== TasksConstants.ROUTE_ALL_TASK &&
            this.routingOption !== TasksConstants.ROUTE_NEW &&
            this.routingOption !== TasksConstants.ROUTE_OPEN &&
            this.routingOption !== TasksConstants.ROUTE_COMPLETED &&
            this.routingOption !== TasksConstants.ROUTE_ASSIGNEDTOME &&
            this.routingOption !== TasksConstants.ROUTE_CREATEDBYME &&
            this.routingOption !== TasksConstants.ROUTE_TODAY_DUE &&
            this.routingOption !== TasksConstants.ROUTE_TOMORROW_DUE &&
            this.routingOption !== TasksConstants.ROUTE_THIS_WEEK_DUE &&
            this.routingOption !== TasksConstants.ROUTE_TASKS_I_WATCH &&
            this.routingOption !== TasksConstants.ROUTE_MY_OVERDUE_TASKS &&
            this.routingOption !== TasksConstants.ROUTE_SEARCH ) {
            this.router.navigate(["/task", TasksConstants.ROUTE_OPEN]);
          }
        }
      }
      this.changerDetectorRef.markForCheck();
    });

    // on displayed tasks changed
    this.store.select(getTasks).pipe(takeWhile(() => this.isAlive)).subscribe(tasksFromStore => {
      this.tasks = tasksFromStore;
      this.changerDetectorRef.markForCheck();
      let taskLength = this.tasks.length;

      // this.sortTasks();

      console.log("[task.main.layout.component] setupStore, getTasks. taskLength: ", taskLength);

      if (this.selectAll) {
        this.store.dispatch(new ResetSelectedTaskId());
        this.selectedTasksIds = [];
        this.selectedTasks = [];
        this.tasks.forEach( task => {
          this.selectedTasksIds.push(task.id);
          this.selectedTasks.push(task);
        });
        for ( let i = 0; i < taskLength; i++) {
          this.tasks[i].isChecked = true;
        }
        this.store.dispatch(new AddSelectedTaskIds(this.selectedTasksIds));
        this.routingBasedOnSelection();
      }

      if ((this.routingOption === this.taskConstants.ROUTE_ALL_TASK ||
        this.routingOption === this.taskConstants.ROUTE_TODAY_DUE ||
        this.routingOption === this.taskConstants.ROUTE_ASSIGNEDTOME ||
        this.routingOption === this.taskConstants.ROUTE_CREATEDBYME ||
        this.routingOption === this.taskConstants.ROUTE_TOMORROW_DUE ||
        this.routingOption === this.taskConstants.ROUTE_TASKS_I_WATCH ||
        this.routingOption === this.taskConstants.ROUTE_THIS_WEEK_DUE ||
        this.routingType === this.taskConstants.ROUTE_TYPE_LIST ||
        this.routingType === this.taskConstants.ROUTE_TYPE_TAG )) {
          if (this.isOpenTasksChecked) {
            this.onCheckShowOnlyOpenTasks(true);
          }

          if (this.isCloseTasksChecked) {
            this.onCheckShowOnlyCloseTasks(true);
          }
      }

      if ((this.routingType === this.taskConstants.ROUTE_TYPE_TAG || this.routingType === this.taskConstants.ROUTE_TASKS_I_WATCH) && this.isShowMyTaskChecked) {
        this.onCheckShowOnlyMyTasks(true);
      }

      // correct scroll position
      const taskListLayout = document.getElementById("taskListLayout");
      if (taskListLayout) {
        if (this.tasks.length === AppConstants.TASKS_LIMIT) {
          taskListLayout.scrollTop = 0;
        } else {
          // taskListLayout.scrollTop = this.previousScrollTopBeforeLoadMore;
          // console.log("[task.main.layout.component] scrolled to : " + this.previousScrollTopBeforeLoadMore + ". current: " + taskListLayout.scrollTop);
        }
      }

      if (taskLength <= this.loadMoreLimit) {
        this.tasksRepo.loadMoreTasks();
      }

      this.onPushBugHack();
      this.ngZone.run(() => {
        setTimeout(() => { this.changerDetectorRef.markForCheck(); });
      });
    });

    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("[task.main.layout.component] isLoading: " + value);
      this.isLoading = value;
      if (!this.isLoading) {
        this.isRefreshing = false;
      }

      this.onPushBugHack();
      this.ngZone.run(() => {
        setTimeout(() => { this.changerDetectorRef.markForCheck(); });
      });
    });

    this.store.select(getIsSearchMode).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isSearchMode = value;
      console.log("[task.main.layout.component] getIsSearchMode: " + value);
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.authUser = value;
      this.changerDetectorRef.markForCheck();
    });

    this.headerService.showSearchDialog$.pipe(takeWhile(() => this.isAlive)).subscribe(value => {
      console.log("[task.main.layout.component] isSearchDialogActive: " + value);
      this.isSearchDialogActive = value;
      this.changerDetectorRef.markForCheck();
    });

    this.responsiveService.screen$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(screen => {
        this.screen = screen;
        this.isMobileDevice = CommonUtil.isOnMobileDevice();
        this.changerDetectorRef.markForCheck();
    });

    this.searchRepo.selectSaveSearches().pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isSaveSearchEnable = value;
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("replaceTaskId").pipe(takeWhile(() => this.isAlive))
      .subscribe(data => {
        const oldTaskId = data["old_id"];
        const newTaskId = data["new_id"];

        console.log("[task.main.layout.component] on replaceTaskId", oldTaskId, newTaskId);

        for (let task of this.tasks) {
          if (task.id === oldTaskId) {
            task.id = newTaskId;

            const indexOldTaskId = this.selectedTasksIds.indexOf(oldTaskId);
            if (indexOldTaskId !== -1) {
              this.selectedTasksIds[indexOldTaskId] = newTaskId;
            }
            console.log("[task.main.layout.component] replaceTaskId replaced");
            break;
          }
        }
      });

    this.broadcaster.on<any>("profileAvatarUpdated").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.updateUserAvatarForTasks();
      });

    this.broadcaster.on<any>("openTaskFromNotification")
      .subscribe(task => {
        if (task.open_in_browser && task.open_in_browser === "true") {
          this.tasksRepo.openTaskExternal(task.id);
        } else {
          let qp: any = { openedFromNotification: 1 };
          if (task.updated_on) {
            qp.taskUpdatedOn = task.updated_on;
          }
          this.broadcaster.broadcast("closeLayoutDrawer");
          this.broadcaster.broadcast("closeAllMdlDialogs");
          this.onTaskClick(task as Task, qp);
          this.changerDetectorRef.markForCheck();
        }
      });

    this.broadcaster.on<any>("showOverdueTasksFromNotification")
      .subscribe(presence => {
        this.broadcaster.broadcast("closeLayoutDrawer");
        this.broadcaster.broadcast("closeAllMdlDialogs");
        this.router.navigate(["/task/overdue"], {});
      });

    this.broadcaster.on<any>("showDueTodayTasksFromNotification")
      .subscribe(presence => {
        this.broadcaster.broadcast("closeLayoutDrawer");
        this.broadcaster.broadcast("closeAllMdlDialogs");
        this.router.navigate(["/task/today"], {});
      });

    this.broadcaster.on<any>("setUnselectAllCheckbox").pipe(takeWhile(() => this.isAlive))
      .subscribe( $event => {
        this.setUnselectAllCheckbox($event);
      });

    this.store
      .select(getSelectedFilterType)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilterType = value;
        this.changerDetectorRef.markForCheck();
      });

    this.store.select(getSelectAll).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.selectAll = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getSelectedTasksIds).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.selectedTasksIds = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getSearchStatisticsInfo).pipe(takeWhile(() => this.isAlive)).subscribe(info => {
      console.log("[task.main.layout.component] getSearchStatisticsInfo: ", info);

      if (info) {
        this.searchStatistic = info;
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getIsDetailView).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isDetailView = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsMultiSelectView).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isMutiSelectView = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsOpenTasksChecked).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isOpenTasksChecked = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsCloseTasksChecked).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isCloseTasksChecked = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsShowMyTaskChecked).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isShowMyTaskChecked = value;
      this.changerDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("deleteTasks").pipe(takeWhile(() => this.isAlive)).subscribe(ids => {
      this.tasksRepo.getTasksStats();
      this.tasksRepo.getTagsListWithCounters();
      if (this.selectedFilterType === TasksConstants.ROUTE_TYPE_LIST) {
        this.tasksRepo.getFolderListWithCounters();
      }
    });

    this.store.select(getIsRightSidebarExpanded).pipe(takeWhile(() => this.isAlive))
    .subscribe(res => {
      this.rightSidebarExpanded = res;
      this.changerDetectorRef.markForCheck();
    });
  }

  // scroll to load more event
  loadMoreTasks(event) {
    console.log("[task.main.layout.component] loadMoreTasks: on SCROLL", event.target.scrollTop, event.target.scrollHeight - (Math.ceil(event.target.scrollTop) + event.target.offsetHeight));

    if (event && event.target) {
      const currentScrollOffset = Math.ceil(event.target.scrollTop) ;
      this.actionWheelMenuService.hideCircularMenu();
      // // block too fast scrolling
      if (this.previousScrollTop !== 0 && currentScrollOffset - this.previousScrollTop > 500){
        console.log("[task.main.layout.component] loadMoreTasks: too fast scrolling is blocked");
        this.previousScrollTop = currentScrollOffset;
      //   return;
      }
      this.previousScrollTop = currentScrollOffset;
      // //

      if (currentScrollOffset + this.oneScreenHeight(event.target) >= event.target.scrollHeight) {
        if (!this.isNextPageLoading && this.isMoreTasks) {
          // console.log("[task.main.layout.component] scroll data", this.previousScrollTopBeforeLoadMore, currentScrollOffset);
          this.previousScrollTopBeforeLoadMore = currentScrollOffset;
          // console.log("[task.main.layout.component] save scroll", this.previousScrollTopBeforeLoadMore);
          this.tasksRepo.loadMoreTasks();
        } else {
          console.log("[task.main.layout.component] loadMoreTasks: skip, isMoreTasks: ", this.isMoreTasks + ", isNextPageLoading: " + this.isNextPageLoading);
        }
      }
    }
  }

  private oneScreenHeight(target) {
    return target.offsetHeight;
  }

  onClearSearch() {
    console.log("[task.main.layout.component] onClearSearch");

    if (this.isOnline || CommonUtil.isSQLSupported()) {
      this.store.dispatch(new SearchModeToggle(false));
      this.router.navigate(["/task", TasksConstants.ROUTE_OPEN], { queryParams: {} });
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  openAddTaskDialog(assignee?: any): void {
    if (this.authUser && this.authUser.team_user === "false") {
      this.tasksRepo.syncAuthUser();
    }
    this.store.dispatch(new SetEditTaskView(false));
    this.matDialog.open(TaskComposeComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "task-compose-dialog",
      data: { assignee: assignee }
    });
    this.changerDetectorRef.markForCheck();
  }

  openTaskComposewithDescDialog(description?: any): void {
    this.store.dispatch(new SetEditTaskView(false));
    this.matDialog.open(TaskComposeComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "task-compose-dialog",
      data: { description: description }
    });
    this.changerDetectorRef.markForCheck();
  }

  onTaskClick(task: Task, qp?: any) {
    console.log("[task.main.layout.component] onTaskClick: ", task);

    let isChecked;
    this.store.dispatch(new SetEditTaskView(false));

    // Check for highlight task details
    if (qp && qp.taskUpdatedOn) {
      this.store.dispatch(new SetTaskDetailHighlight(true));
    } else {
      this.store.dispatch(new SetTaskDetailHighlight(false));
    }

    // Mobile app
    if (CommonUtil.isOnMobileDevice()) {
      if (this.selectedTasksIds.length === 0) {
        this.onClearSelectedTasks(task);
        if (!qp) { qp = {}; }
        this.router.navigate(["detail", task.id], { queryParams: qp, relativeTo: this.activated});
      } else {
        isChecked = !task.isChecked;
        this.onSelectTask({
          state: isChecked,
          task: task
        });
      }

    // Web app
    } else {
      if (this.selectedTasksIds.length > 1) {
        isChecked = true;
      } else {
        isChecked = !task.isChecked;
      }
      this.onClearSelectedTasks(task);
      this.onSelectTask({
        state: isChecked,
        task: task
      });
    }
    this.changerDetectorRef.markForCheck();
  }

  onFilterSelect(item: any) {
    this.store.dispatch(new SetSortBy(item));
    this.selectedTasks = [];
    this.store.dispatch(new SetSelectAll(false));
    this.store.dispatch(new ResetSelectedTaskId());
    this.activated.params.pipe(takeWhile(() => this.isAlive)).subscribe(params => {
      if (this.isOnline) this.tasksRepo.getTasks();
      else this.tasksRepo.getDataFromDatabase(params.filter);
    })
  }

  onCheckShowOnlyOpenTasks(event: boolean) {
    console.log("[task.main.layout.component] onCheckShowOnlyOpenTasks", event);

    this.clearAll(true);

    // checked
    if (event) {
      this.store.dispatch(new SetOpenTasksChecked(true));

      this.tasks = this.tasks.filter (task => {
        return task.status.id !== TaskUtils.statusCompletedId();
      });
      this.onPushBugHack();

      if (this.totalOpenTasks > 0) {
        let count = this.tasks.length;
        if ( (count - this.totalOpenTasks) <= this.loadFilterTaskLimit) {
          this.tasksRepo.loadMoreTasks();
        } else {
          this.totalOpenTasks = count;
        }
      } else {
        this.totalOpenTasks = this.tasks.length;
        if ( this.totalOpenTasks <= this.loadFilterTaskLimit) {
          this.tasksRepo.loadMoreTasks();
        }
      }

    // unchecked
    } else {
      this.store.dispatch(new SetOpenTasksChecked(false));

      this.store.select(getTasks).pipe(take(1)).subscribe(tasks => {
        this.tasks = tasks;
        this.changerDetectorRef.markForCheck();
      });

      this.totalOpenTasks = 0;

      if (this.isShowMyTaskChecked) {
        this.onCheckShowOnlyMyTasks(true);
      }
    }

    if (this.tasks.length <= this.loadMoreLimit) {
      this.tasksRepo.loadMoreTasks();
    }
    this.changerDetectorRef.markForCheck();
  }

  onCheckShowOnlyCloseTasks(event: boolean) {
    console.log("[task.main.layout.component] onCheckShowOnlyCloseTasks", event);

    this.clearAll(true);

    // checked
    if (event) {
      this.store.dispatch(new SetCloseTasksChecked(true));

      this.tasks = this.tasks.filter (task => {
        return task.status.id === TaskUtils.statusCompletedId();
      });
      this.changerDetectorRef.markForCheck();

      if (this.totalCloseTasks > 0) {
        let count = this.tasks.length;
        if ( (count - this.totalCloseTasks) <= this.loadFilterTaskLimit) {
          this.tasksRepo.loadMoreTasks();
        } else {
          this.totalCloseTasks = count;
        }
      } else {
        this.totalCloseTasks = this.tasks.length;
        if ( this.totalCloseTasks <= this.loadFilterTaskLimit) {
          this.tasksRepo.loadMoreTasks();
        }
      }
    // unchecked
    } else {
      this.store.dispatch(new SetCloseTasksChecked(false));

      this.store.select(getTasks).pipe(take(1)).subscribe(tasks => {
        this.tasks = tasks;
        this.changerDetectorRef.markForCheck();
      });

      this.totalCloseTasks = 0;

      if (this.isShowMyTaskChecked) {
        this.onCheckShowOnlyMyTasks(true);
      }
    }

    if (this.tasks.length <= this.loadMoreLimit) {
      this.tasksRepo.loadMoreTasks();
    }
    this.changerDetectorRef.markForCheck();
  }

  onCheckShowOnlyMyTasks(event: boolean) {
    console.log("[task.main.layout.component] onCheckShowOnlyMyTasks", event);

    this.clearAll(true);

    // checked
    if (event) {
      this.store.dispatch(new SetShowMyTaskChecked(true));

      this.tasks = this.tasks.filter (task => {
        return this.authUser.id === ((task.assigned_to) ? task.assigned_to.id : -1 ) || this.authUser.id === task.author.id;
      });
      this.changerDetectorRef.markForCheck();

      if (this.totalMyTasks > 0) {
        let count = this.tasks.length;
        if ( (count - this.totalMyTasks) <= this.loadFilterTaskLimit) {
          this.tasksRepo.loadMoreTasks();
        } else {
          this.totalMyTasks = count;
        }
      } else {
        this.totalMyTasks = this.tasks.length;
        if ( this.totalMyTasks <= this.loadFilterTaskLimit) {
          this.tasksRepo.loadMoreTasks();
        }
      }

    // unchecked
    } else {
      this.store.dispatch(new SetShowMyTaskChecked(false));

      this.store.select(getTasks).pipe(take(1)).subscribe(tasks => {
        this.tasks = tasks;
        this.changerDetectorRef.markForCheck();
      });

      this.totalMyTasks = 0;

      if (this.isOpenTasksChecked) {
        this.onCheckShowOnlyOpenTasks(true);
      }

      if (this.isCloseTasksChecked) {
        this.onCheckShowOnlyCloseTasks(true);
      }
    }

    if (this.tasks.length <= this.loadMoreLimit) {
      this.tasksRepo.loadMoreTasks();
    }
    this.changerDetectorRef.markForCheck();
  }

  // only if screen === 'desktop'
  onClickReloadTasks() {
    if (this.isOnline) {
      this.selectedTasks = [];
      this.tasksRepo.reloadApp();
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  logout() {
    console.log("[task.main.layout.component] logout");
    this.tasksRepo.logout();
  }

  ngOnDestroy(): void {
    console.log("[task.main.layout.component] ngOnDestroy");
    this.isAlive = false;
  }

  onClearSelectedTasks($event) {
    $event.isSelected = true;
    if (this.preSelectedTask) {
      this.preSelectedTask.isSelected = false;
    }

    if (this.selectedTasks.length > 0) {
      for (let i = 0; i < this.selectedTasks.length; i++) {
        let task = this.tasks.find( task => this.selectedTasks[i].id === task.id);
        if (task) {
          task.isChecked = false;
        }
      }
      this.store.dispatch(new SetSelectAll(false));
      this.store.dispatch(new ResetSelectedTaskId());
      this.selectedTasks = [];
    }
    this.preSelectedTask = $event;
  }

  clickEventManage(event: any) {
    switch (event.type) {
      case BulkUpdateIssueType.Priority:
      case BulkUpdateIssueType.Project:
      case BulkUpdateIssueType.StartDate:
      case BulkUpdateIssueType.DueDate:
      case BulkUpdateIssueType.Recurring:
      case BulkUpdateIssueType.AssignedTo:
      case BulkUpdateIssueType.Tags:
      case BulkUpdateIssueType.List:
      case BulkUpdateIssueType.Location:
        this.openBulkEditDialog(event.type);
        break;
      case BulkUpdateIssueType.ClearAll:
        this.clearAll(event.event);
        break;
      case BulkUpdateIssueType.MarkAll:
        this.markAll(event.event);
        break;
      case BulkUpdateIssueType.Delete:
        this.deleteSelectedTasks();
        break;
      case BulkUpdateIssueType.Complete:
        this.completeSelectedTasks();
        break;
      case BulkUpdateIssueType.Duplicate:
        this.duplicateSelectedTasks();
        break;
      case BulkUpdateIssueType.Watchers:
        this.openAddWatcherBulk();
        break;
    }
  }

  duplicateSelectedTasks(): void {
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.OK, message: this.messageTranslatorService.getMessage(TasksConstants.CONFIRM_DUPLICATE_SELECTED_TASKS), header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      this.onConfirmDuplicateDialogClose(res);
    });
  }

  onConfirmDuplicateDialogClose($event: boolean): void {
    let selectedTasks = this.tasks.filter( task => this.selectedTasksIds.includes(task.id));
    if ($event) {
      if (this.selectedTasksIds.length > 0 ) {
        this.tasksRepo.duplicateTasks(selectedTasks);
      }
    }
  }

  completeSelectedTasks(): void {
    console.log("task.main.layout.component completeSelectedTasks");
    const withoutETA = [];
    const withETA    = [];
    this.selectedTasks.map(selTask =>{
      if (selTask.estimated_hours) {
        withETA.push(selTask)
      } else {
        withoutETA.push(selTask)
      }
    })
    let args: BulkUpdateArgs = { id: TaskUtils.statusCompletedId(), name: TaskUtils.statusCompletedName() };
    if (this.selectedTasksIds.length > 0 && withoutETA.length) {
      if (this.isOnline || CommonUtil.isSQLSupported()) {
        this.tasksRepo.updateBulkTasks(withoutETA, BulkUpdateIssueType.Status, args);
      }
    }
    if (this.selectedTasksIds.length > 0 && withETA.length) {
      if (this.isOnline || CommonUtil.isSQLSupported()) {
        setTimeout(() => {
          this.tasksRepo.updateBulkTasks(withETA, BulkUpdateIssueType.Status, args);
        }, 1000);
      }
    }
    if (!this.isOnline) {
      let tasks = [...withoutETA, ...withETA];
      const backUpTaskList = tasks;
      tasks = tasks.filter(i => i.status.name !== args.name);
      if (!tasks.length) return
      tasks.forEach( (elem) => {
        elem.isSelected = false;
      });
      const id = Math.floor(Math.random() * 1000);
      const task = {
        id: id,
        body: {
          oldTasksBackUp: backUpTaskList,
          tasks: tasks,
          status: BulkUpdateIssueType.Status,
          args: args,
          operationType : "bulkUpdate"
        }
      }
      let taskStats = [];
      this.store.select(getTaskStatisticsInfo).subscribe(info => {
        if (info) {
          taskStats = info;
        }
      });
      this.databaseService.addPendingOperationTask([task]).subscribe(updateToDBdone => {
        for (let item of updateToDBdone) {
          this.resetTasks();
          this.store.dispatch(new SetEditTaskView(false));
          for (let store of TaskRepository.taskStoreList) {
            this.databaseService.getTaskById(item.id, store).subscribe(t => {
              if (t) {
                item.status = args;
                item.isChecked = false;
                this.databaseService.addBulkTasks([item], store).subscribe(done => {
                  if (store === "OpenTaskStore" || store === "CompletedTaskStore" || store === "NewTaskStore") {
                    if (store === "OpenTaskStore") {
                      this.databaseService.deleteBulkTasks(t.id, "OpenTaskStore");
                      this.databaseService.addBulkTasks([item], "CompletedTaskStore");
                      for (let stat of taskStats) {
                        if (stat.type == "completed") {
                          stat.info.completed_count = stat.info.completed_count + 1;
                          stat.info.total_count = stat.info.total_count + 1;
                        }
                        if (stat.type == "open") {
                          stat.info.total_count = stat.info.total_count - 1;
                        }
                      }
                    }
                    if (store === "NewTaskStore") {
                      this.databaseService.deleteBulkTasks(t.id, "NewTaskStore");
                      for (let stat of taskStats) {
                        if (stat.type == "new") {
                          stat.info.total_count = stat.info.total_count - 1;
                        }
                      }
                    }
                    this.databaseService.addTaskStats(taskStats).subscribe(done => {
                      this.tasksRepo.getTasksStats();
                      this.activated.params.subscribe(params => {
                        this.tasksRepo.getDataFromDatabase(params.filter);
                      })
                    })
                  }
                })
              }
            });
          }
        }
      });
    }

    // if (this.selectedTasksIds.length > 0 ) {
    //   if (this.isOnline || CommonUtil.isSQLSupported()) {
    //     this.tasksRepo.updateBulkTasks(this.selectedTasks, BulkUpdateIssueType.Status, args);
    //   } else {
    //     this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    //   }
    // }
  }

  deleteSelectedTasks(): void {
    console.log("task.main.layout.component deleteSelectedTasks");
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      data: { type: this.dialogType.DELETE, message: this.messageTranslatorService.getMessage(TasksConstants.DELETE_TASK_MESSAGE), header: null }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      this.onConfirmTasksDeleteDialogClose(res);
    });
  }

  onConfirmTasksDeleteDialogClose($event: boolean): void {
    if ($event) {
      if (this.selectedTasksIds.length > 0 ) {
        this.tasksRepo.removeTasks(this.selectedTasksIds);
      }
    }
  }

  resetTasks(): void {
    this.selectedTasks = [];
    this.store.dispatch(new SetSelectAll(false));
    this.store.dispatch(new ResetSelectedTaskId());
  }

  markAll(ev) {
    if (ev) {
      this.selectedTasks = [];
      this.store.dispatch(new ResetSelectedTaskId());
      for (let i = 0; i < this.tasks.length; i++) {
        this.onSelectTask({
          state: true,
          task: this.tasks[i]
        });
      }
    } else {
      for (let i = 0; i < this.tasks.length; i++) {
        this.onSelectTask({
          state: false,
          task: this.tasks[i]
        });
      }
    }
  }

  clearAll(ev) {
    for (let i = 0; i < this.tasks.length; i++) {
      this.tasks[i].isChecked = false;
    }
    this.resetTasks();
    this.routingBasedOnSelection();
    this.onPushBugHack();
  }

  openBulkEditDialog(type) {
    const dlg = this.matDialog.open(TaskBulkEditDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctask-bulk-edit-dialog",
      data: { dialogType: type, selectedTasks: this.selectedTasks, projectId: (this.selectedTasks[0].project) ? this.selectedTasks[0].project.id : 0 }
    });
  }

  openAddWatcherBulk() {
    this.tasksRepo.getMemberList(this.selectedTasks[0].project.id);
    const dlg = this.matDialog.open(TaskWatchersComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-watchers-dialog"
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res) {
        let watchers = JSON.stringify(res);
        let args: BulkUpdateArgs = { value: watchers };
        this.tasksRepo.updateBulkTasks(this.selectedTasks, BulkUpdateIssueType.Watchers, args);
      }
    });
  }

  onSelectTask($event): void {
    this.store.dispatch(new SetEditTaskView(false));
    if (this.preSelectedTask) {
      this.preSelectedTask.isSelected = false;
    }

    if ($event.state) {
      this.store.dispatch(new AddSelectedTaskId($event.task.id));
      this.selectedTasks.push($event.task);
      $event.task.isChecked = true;
      if (this.selectedTasksIds.length === this.tasks.length) {
        this.store.dispatch(new SetSelectAll(true));
      }
    } else {
      for (let i = 0; i < this.selectedTasksIds.length; i++) {
        if (this.selectedTasksIds[i] === $event.task.id) {
          this.selectedTasks.splice(i, 1);
          this.store.dispatch(new RemoveSelectedTaskId(this.selectedTasksIds[i]));
          $event.task.isChecked = false;
          this.store.dispatch(new SetSelectAll(false));
          break;
        }
      }

      if (this.selectedTasksIds.length === 0) {
        if (this.headerMenubar.contextPopover.isVisible) {
          this.headerMenubar.contextPopover.hide();
        }
      }
    }
    this.routingBasedOnSelection();
    this.isTaskInSameProject();
    this.onPushBugHack();
  }

  routingBasedOnSelection() {

    if (CommonUtil.isOnMobileDevice()) {
      if (this.selectedTasksIds.length === 0) {
        this.store.dispatch(new SetMultiSelectTaskView(false));
        this.store.dispatch(new SetDetailView(false));
        this.router.navigate(["./"], { relativeTo: this.activated });
        // do not need to open TaskStatisticsComponent on mobile
        // this.router.navigate(["./"], { relativeTo: this.activated });
      }
      if (this.selectedTasksIds.length === 1) {
        this.store.dispatch(new SetMultiSelectTaskView(true));
        this.store.dispatch(new SetDetailView(false));
        this.router.navigate(["multi-select"], { relativeTo: this.activated });
      }
    } else {
      if (this.selectedTasksIds.length === 0) {
        if (this.isDetailView) {
          this.store.dispatch(new SetDetailView(false));
          this.router.navigate(["./"], { relativeTo: this.activated });
        } else {
          this.store.dispatch(new SetMultiSelectTaskView(false));
          this.router.navigate(["./"], { relativeTo: this.activated });
        }
      }
      if (this.selectedTasksIds.length === 1) {
        this.router.navigate(["detail", this.selectedTasksIds[0]], { queryParams: {}, relativeTo: this.activated});
      }
      if (this.selectedTasksIds.length > 1) {
        this.store.dispatch(new SetMultiSelectTaskView(true));
        this.store.dispatch(new SetDetailView(false));
        this.router.navigate(["multi-select"], { relativeTo: this.activated });
      }
    }
  }

  isTaskInSameProject() {
    if (this.selectedTasksIds.length > 0) {
      let firstProjectId = this.selectedTasks[0].project.id;
      if (this.selectedTasks.length === 1) {
        this.isChangeUserOptionVisible = true;
      } else {
        for (let i = 1; i < this.selectedTasks.length; i++) {
          if (this.selectedTasks[i].project.id !== firstProjectId) {
            this.isChangeUserOptionVisible = false;
          } else {
            this.isChangeUserOptionVisible = true;
          }
        }
      }
    }
  }

  setUnselectAllCheckbox(event) {
    this.clearAll(event);
  }

  private onPushBugHack() {
    setTimeout(() => {
    this.changerDetectorRef.markForCheck();
    }, 100);
  }

  saveSearchQuery() {
    console.log("[task.main.layout.component] saveSearchQuery");
    if (this.isOnline) {
      this.openQueryDialog();
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  openQueryDialog() {
    const dlg = this.matDialog.open(SaveQueryDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-save-query-dialog"
    });
  }

  private updateUserAvatarForTasks() {
    if (this.tasks.length > 0) {
      // TODO: to populate only current user assigned tasks avatar (not all)
      let tasks = this.tasks.filter(task => task.assigned_to && task.assigned_to.id === this.authUser.id);
      const changes = tasks.map(task => {
        task.userAvatar = this.authUser.userAvatar;
        return { id: task.id, changes: task };
      });
      this.store.dispatch(new UpdateTasksSuccess({ tasks: changes }));

      this.changerDetectorRef.markForCheck();
    }
    this.tasksRepo.updateMembersAvatars(this.authUser);
    this.tasksRepo.poppulateAllUserAvatar(this.authUser);
  }

}
