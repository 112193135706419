
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  HostListener,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { MdlSnackbarService, MdlDialogService, MdlDialogReference } from "@angular-mdl/core";
import { Priority } from "../../models/priority";
import { TranslateService } from "@ngx-translate/core";
import { User, Task, BulkUpdateArgs, ICompact, List, Location } from "../../models";
import { DatePipe } from "@angular/common";
import { BulkUpdateIssueType, DateFilterType, SuccessType } from "../../shared/task-enum";
import { TaskRepository } from "../../repository/task.repository";
import { TaskUtils } from "../../shared/task-utils";
import { TasksConstants } from "../../shared/task-constacts";
import { Store } from "@ngrx/store";
import { TasksRootState, getMemberList, getProjectsList, getPriorityList, getFolderList, getAuthUser, getTasksIsLoading, getLocationList, getTagList } from "../../store/index";
import { Project } from "../../models/project";
import { SuccessService } from "../../../common/providers/success-service";
import { ConfigService } from "../../../common/providers/config.service";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { TaskDatePickerComponent } from "../task-date-picker/task-date-picker.component";
import { getOnlineStatus } from "../../../reducers";
import { UntypedFormControl } from "@angular/forms";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-task-bulk-edit-dialog",
  template: `
      <div id="bulk-edit-priority-dialog" class="vnctask-bulk-edit-dialog task__dialog open">
      <div class="task__dialog-header">
        <div class="mobile-back-button">
          <button mat-button (click)="hideDialog()">
            <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </div>
        <div class="header_lbl disable-select">
          {{ header }}
        </div>
        <div class="desktop-close-button">
          <button mat-button (click)="hideDialog()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
        </div>
      </div>
        <div class="task__dialog-body" [class.save-mode]="((authUser ? (authUser.team_user === 'false'): true ) && email && email.value !== '') && dialogType == bulkUpdateIssueType.AssignedTo">

        <div class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Priority">
        <mdl-list>
          <mdl-list-item
            *ngFor="let item of priorityList; let i = index;"
            [class.selected]="i == selectedIndex"
            (click)="onPriorityClick(item)">
              <mdl-icon [style.color]="getPriorityColor(item)">assignment_late</mdl-icon>
              <span>{{item.name | uppercase | translate}}</span>
            </mdl-list-item>
        </mdl-list>
       </div>

      <div id="bulk-edit-assignedto-dialog" class="option-list assign-option-list" *ngIf="dialogType == bulkUpdateIssueType.AssignedTo">
        <mdl-textfield *ngIf="authUser ? (authUser.team_user === 'true'): false" class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_USER' | translate"  floating-label></mdl-textfield>
        <mat-form-field class="" *ngIf="authUser ? (authUser.team_user === 'false'): true" hintLabel="{{ 'HINTLABEL_FOR_INVITE_EXTERNAL' | translate }}">
          <span class="invite-external-label">{{ 'ASSIGN_TO' | translate }}:</span>
          <input type="email" [formControl]="email" autocomplete="email" [(ngModel)]="searchText" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput>
          <mat-error *ngIf="email.errors?.pattern">
            {{ 'EMAIL_INVALID_ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <mdl-list>
         <mdl-list-item
           *ngFor="let assignUser of assignUserList  | vpTaskUserSearch : searchText; let i = index;"
           [class.selected]="i == selectedIndex"
           (click)="onAssignUsersClick(assignUser)">
	   <vp-avatar [avatarId]="assignUser.id" [user]="{ name: assignUser && assignUser.name ? assignUser.name : '', avatarURL: assignUser.avatar }"> </vp-avatar>
           <span class="username-place">{{ (assignUser) ? assignUser.name : ('NO_NAME' | translate) }}</span>
           </mdl-list-item>
         </mdl-list>
      </div>
      <div id="bulk-edit-project-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Project">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_PROJECT' | translate"  floating-label></mdl-textfield>
      <mdl-list>
        <mdl-list-item
        *ngFor="let project of projectList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onProjectClick(project)">
          <mdl-icon>work</mdl-icon>
          <span>{{project.name}}</span>
          </mdl-list-item>
        </mdl-list>
     </div>
     <div id="bulk-edit-list-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.List">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_LIST' | translate"  floating-label></mdl-textfield>
      <mdl-list>
        <mdl-list-item
        *ngFor="let list of folderList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onListClick(list)">
          <mdl-icon>assignment</mdl-icon>
          <span class="list-span">{{list.name}}</span>
          </mdl-list-item>
        </mdl-list>
     </div>

     <div id="bulk-edit-tag-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Tags">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_TAG' | translate"  floating-label></mdl-textfield>
      <mdl-list>
        <mdl-list-item
        *ngFor="let tag of tagList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onTagClick(tag)">
          <mdl-icon>local_offer</mdl-icon>
          <span>{{tag.name}}</span>
          </mdl-list-item>
        </mdl-list>
     </div>

     <div id="bulk-edit-location-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Location">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'SEARCH_LOCATION' | translate"  floating-label></mdl-textfield>
      <mdl-list>
        <mdl-list-item
        *ngFor="let location of locationList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onLocationClick(location)">
          <mdl-icon>place</mdl-icon>
          <span class="list-span">{{location.name}}</span>
          </mdl-list-item>
        </mdl-list>
     </div>

     <div id="bulk-edit-recurring-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Recurring">
     <mdl-list>
       <mdl-list-item
       *ngFor="let repeat of repeatItems; let i = index;"
         [class.selected]="i == selectedIndex"
         (click)="onRepeatClick(repeat.value)">
         <mdl-icon>autorenew</mdl-icon>
         <span>{{repeat.name}}</span>
         </mdl-list-item>
       </mdl-list>
      </div>
        <div id="bulk-edit-date-dialog" class="date-option-list" *ngIf="dialogType === bulkUpdateIssueType.StartDate || dialogType === bulkUpdateIssueType.DueDate">
        <mdl-list>
          <mdl-list-item *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="onDateSelect(item, dialogType)">
            <mdl-icon *ngIf = "dialogType===bulkUpdateIssueType.StartDate && item.date">today</mdl-icon>
            <mdl-icon *ngIf = "dialogType===bulkUpdateIssueType.DueDate && item.date">event</mdl-icon>
            <mdl-icon *ngIf = "!item.date">event_busy</mdl-icon>
            <div>
              <span class="dateDesc">{{ item.name }}</span>
              <span class="align-right" *ngIf="item.date"> {{item.date | vpLocaleDate: 'MMM d'}} </span>
              <span class="align-right" *ngIf="!item.date">None</span>
            </div>
          </mdl-list-item>
        </mdl-list>
        <div class="footer-div">
          <button *ngIf = "dialogType===bulkUpdateIssueType.StartDate" class="date-picker-button mdl-button" (click)='openDatePicker()'>
            <mdl-icon>today</mdl-icon>
            <span>{{ 'PICK_START_DATE' | translate }}</span>
          </button>
          <button *ngIf = "dialogType===bulkUpdateIssueType.DueDate" class="date-picker-button mdl-button" (click)='openDatePicker()'>
            <mdl-icon>event</mdl-icon>
            <span>{{ 'PICK_DUE_DATE' | translate }}</span>
          </button>
        </div>
      </div>
        </div>
        <div *ngIf="((authUser ? (authUser.team_user === 'false'): true ) && email.value !== '') && dialogType == bulkUpdateIssueType.AssignedTo" class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="hideDialog()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="saveInviteUserClick(searchText)">
                {{ 'UPDATE' | translate }}
            </button>
        </div>
        <div class="mobile-footer">
            <div class="title">
                {{ 'SAVE_ASSIGNED_USERS' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="saveInviteUserClick(searchText)">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
      </div>
      </div>
      <div class="vnctask-loading" *ngIf="isOnIOS && isLoading">
        <div class="indicator">
          <mdl-spinner single-color active="true"></mdl-spinner>
        </div>
      </div>
      <div class="vnctask-loading" *ngIf="isUpdateInProcess">
        <div class="indicator">
          <mdl-spinner single-color active="true"></mdl-spinner>
        </div>
      </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBulkEditDialogComponent implements OnInit, OnDestroy {
  filteredItems: any[] = [];
  selectedTasks: Task[] = [];
  item: string[] = [];
  dialogType: any;
  assignUserList: User[] = [];
  priorityList: Priority[] = [];
  projectList: Project[] = [];
  tagList: ICompact[] = [];
  folderList: List[] = [];
  locationList: Location[] = [];
  repeatItems: any[] = [];
  @Input() header: string;
  datePipe: DatePipe = new DatePipe("en");
  bulkUpdateIssueType = BulkUpdateIssueType;
  isAlive = true;
  rangeStart = new Date();
  searchText: string = "";
  isOnline: boolean = false;
  authUser: any = {};
  email = new UntypedFormControl("");
  isLoading: boolean = false;
  isOnIOS: boolean = CommonUtil.isOnIOS();
  mailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  projectId: number;
  isUpdateInProcess: boolean = false;

  constructor(
    private translate: TranslateService,
    private mdlSnackbarService: MdlSnackbarService,
    private tasksRepo: TaskRepository,
    private store: Store<TasksRootState>,
    private successService: SuccessService,
    private configService: ConfigService,
    private broadcaster: Broadcaster,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogService: MdlDialogService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<TaskBulkEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogType = this.data.dialogType;
    this.selectedTasks = this.data.selectedTasks;
    if (this.data && this.data.projectId) {
      this.projectId = this.data.projectId;
    }
    this.setupStore();
    this.handleBulkUpdateSuccessMessages();
    this.rangeStart.setDate(this.rangeStart.getDate() - 1);

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.hideDialog();
      });

    this.broadcaster.on<any>("hideBulkEditDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hideDialog();
      });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });

    if (this.dialogType === BulkUpdateIssueType.AssignedTo) {
      if (this.authUser && this.authUser.team_user === "false") {
        this.tasksRepo.syncAuthUser();
      }
      this.openDialogForAssignUser(this.dialogType, this.selectedTasks, this.projectId);
    } else {
      this.show(this.dialogType, this.selectedTasks);
    }
  }

  ngOnInit() { }

  show(dialogType: any, selectedTasks: Task[]): void {

    if (dialogType === BulkUpdateIssueType.Priority) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.PRIORITY);
    } else if (dialogType === BulkUpdateIssueType.Project) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TASK_PROJECT);
    } else if (dialogType === BulkUpdateIssueType.AssignedTo) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.ASSIGN_USER);
    } else if (dialogType === BulkUpdateIssueType.StartDate) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TASK_START_DATE);
      this.setFilterDateOption(DateFilterType.StartDate);
    } else if (dialogType === BulkUpdateIssueType.DueDate) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TASK_DUE_DATE);
      this.setFilterDateOption(DateFilterType.DueDate);
    } else if (dialogType === BulkUpdateIssueType.Recurring) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.CHANGE_REPEAT_PATTERN);
      this.setRepeatItems();
    } else if (dialogType === BulkUpdateIssueType.Tags) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TAGS);
    } else if (dialogType === BulkUpdateIssueType.List) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.LISTS);
    } else if (dialogType === BulkUpdateIssueType.Location) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.LOCATIONS);
    }
    this.changerDetectorRef.markForCheck();
  }

  setupStore() {
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe(projects => {
      if (projects) {
        this.projectList = projects;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getPriorityList).pipe(takeWhile(() => this.isAlive)).subscribe(priorities => {
      if (priorities) {
        this.priorityList = priorities;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getMemberList).pipe(takeWhile(() => this.isAlive)).subscribe(members => {
      if (members) {
        this.assignUserList = members;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getTagList).pipe(takeWhile(() => this.isAlive)).subscribe(tags => {
      if (tags) {
        this.tagList = tags;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getFolderList).pipe(takeWhile(() => this.isAlive)).subscribe(lists => {
      if (lists) {
        this.folderList = lists;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getLocationList).pipe(takeWhile(() => this.isAlive)).subscribe(locations => {
      if (locations) {
        this.locationList = locations;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("[task-bulk-edit-dialog.component] isLoading: " + value);
      this.isLoading = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  handleBulkUpdateSuccessMessages() {
    // console.log("[" + this.constructor.name + "][handleBulkUpdateSuccessMessages]", this.dialogType );

    this.successService.only(SuccessType.TaskBulkUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.mdlSnackbarService.showToast(
        success.messages
      );
      setTimeout(() => {
        this.isUpdateInProcess = false;
        this.changerDetectorRef.markForCheck();
      }, 100);
      if (this.dialogType !== BulkUpdateIssueType.Status) {
        this.hideDialog();
      }
      if (this.dialogType === BulkUpdateIssueType.Tags) {
        this.tasksRepo.getTagsListWithCounters();
      }
      if (this.dialogType === BulkUpdateIssueType.List) {
        this.tasksRepo.getFolderListWithCounters();
      }
      if (this.dialogType === BulkUpdateIssueType.Location) {
        this.tasksRepo.getLocationsWithCounters();
      }
      this.broadcaster.broadcast("setUnselectAllCheckbox");

      if ((this.dialogType !== BulkUpdateIssueType.AssignedTo) || (this.dialogType !== BulkUpdateIssueType.Priority) || (this.dialogType !== BulkUpdateIssueType.Project) || (this.dialogType !== BulkUpdateIssueType.Recurring) || (this.dialogType !== BulkUpdateIssueType.Tags) ) {
        this.tasksRepo.getTasksStats();
      }
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  public hideDialog(): void {
    this.searchText = "";
    this.changerDetectorRef.markForCheck();
    this.dialogRef.close();
  }

  openDialogForAssignUser(
    dialogType: string,
    selectedTasks: Task[],
    projectId: number
  ) {
    this.tasksRepo.getMemberList(projectId);
    this.show(dialogType, selectedTasks);
  }

  onPriorityClick(priority: Priority) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: priority.id, name: priority.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Priority, item);
  }

  onProjectClick(project: Project) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: project.id, name: project.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Project, item);
  }

  onTagClick(tag: ICompact) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: tag.id, name: tag.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Tags, item);
  }

  onListClick(list: List) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: list.id, name: list.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.List, item);
  }

  onLocationClick(location: Location) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: location.id, name: location.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Location, item);
  }

  onDateSelect(event, type) {
    this.tasksRepo.FormattedDateForDB = event?.date;
    this.isUpdateInProcess = true;
    console.log("[TaskBulkEditDialog][onDateSelect]", event, type);

    let setDate = null;
    if (event !== null) {
      if (event.date || event.date === null) {
        setDate = this.dateSelect(event.date);
      } else {
        setDate = this.dateSelect(event);
      }
    }
    console.log("[TaskBulkEditDialog][onDateSelect] setDate", setDate);

    // validate due_date > start_date
    let defaultDate = TaskUtils.dueDateOrStartDateNull();
    if (setDate) {
      let invalidTasksCounter = 0;
      if (type === BulkUpdateIssueType.StartDate) {
        const sd = new Date(setDate);
        this.selectedTasks.forEach(t => {
          const dd = t.due_date;
          if (dd && dd.getTime() !== defaultDate.getTime() && dd < sd) {
            ++invalidTasksCounter;
          }
        });
      } else if (type === BulkUpdateIssueType.DueDate) {
        const dd = new Date(setDate);
        this.selectedTasks.forEach(t => {
          const sd = t.start_date;
          if (sd && sd.getTime() !== defaultDate.getTime() && dd < sd) {
            ++invalidTasksCounter;
          }
        });
      }

      console.log("[TaskBulkEditDialog][onDateSelect] invalidTasksCounter: ", invalidTasksCounter);

      if (invalidTasksCounter > 0) {
        this.tasksRepo.errorDueDateInvalid();
        this.isUpdateInProcess = false;
        return;
      }
    }

    const item: BulkUpdateArgs = { value: setDate };
    this.updateTask(this.selectedTasks, type, item);
  }

  updateTask(selectedTasks: Task[], type: BulkUpdateIssueType, item: BulkUpdateArgs) {
    if (type !== BulkUpdateIssueType.Recurring) {
      this.tasksRepo.updateBulkTasks(this.selectedTasks, type, item);
    } else {
      this.tasksRepo.updateBulkTasksRecurring(this.selectedTasks, type, item);
    }
  }

  dateSelect(event) {
    return this.datePipe.transform(event, "yyyy-MM-dd");
  }

  onAssignUsersClick(user) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: user.id, name: user.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.AssignedTo, item);
  }

  saveInviteUserClick(email) {
    if (email.match(this.mailFormat)) {
      let canInvite = true;
      let isPermission = true;
      this.selectedTasks.forEach(task => {
        if (task.author.id !== this.authUser.id) {
          canInvite = false;
        }
        if (task.can_invite_users === "false") {
          isPermission = false;
        }
      });
      if (!isPermission) {
        this.mdlSnackbarService.showToast(
          this.messageTranslatorService.getMessage(TasksConstants.PERMISSION_DECLINED)
        );
        return false;
      }
      if (canInvite) {
        if (this.authUser.can_invite_users === "true") {
          let item: BulkUpdateArgs = { value: email };
          this.updateTask(this.selectedTasks, BulkUpdateIssueType.InviteTo, item);
        } else {
          this.mdlSnackbarService.showToast(
            this.messageTranslatorService.getMessage(TasksConstants.EXCEED_LIMIT_FOR_INVITE)
          );
          return false;
        }
      } else {
        this.mdlSnackbarService.showToast(
          this.messageTranslatorService.getMessage(TasksConstants.ONLY_AUTHOR_CAN_INVITE)
        );
        return false;
      }
    } else {
      this.mdlSnackbarService.showToast(
        this.messageTranslatorService.getMessage(TasksConstants.ENTER_VALID_EMAIL_ADDRESS)
      );
      return false;
    }
  }

  onRepeatClick(repeatUnit) {
    let item: BulkUpdateArgs = { value: repeatUnit };
    if (!this.isUpdateInProcess) {
      this.isUpdateInProcess = true;
      this.updateTask(this.selectedTasks, BulkUpdateIssueType.Recurring, item);
    }
  }

  setFilterDateOption(msg_no_date) {
    this.filteredItems = this.tasksRepo.getDateFilterItems(msg_no_date);
    this.changerDetectorRef.markForCheck();
  }

  setRepeatItems() {
    this.repeatItems = this.tasksRepo.getRepeatItems();
    this.changerDetectorRef.markForCheck();
  }

  getPriorityColor(item) {
    return TaskUtils.getPriorityColor(item);
  }

  openDatePicker() {
    const dlg = this.matDialog.open(TaskDatePickerComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-datepicker-dialog",
      data: { header: (this.dialogType === BulkUpdateIssueType.StartDate) ? this.messageTranslatorService.getMessage(TasksConstants.PICK_START_DATE) : this.messageTranslatorService.getMessage(TasksConstants.PICK_DUE_DATE), rangeStart: this.rangeStart}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.onDateSelect(res, this.dialogType);
      }
    });
  }

  private onPushBugHack() {
    setTimeout(() => {
      this.changerDetectorRef.markForCheck();
    }, 100);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
