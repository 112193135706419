
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<mdl-layout-header-row (click)="switchApplication($event)">
  <!-- Left -->
  <div class="switch-app">
    <div class="switch-button-app" appswitch="true" [class.active-btn]="switchApp" (click)="$event.switch = true">
      <mdl-icon>apps</mdl-icon>
    </div>
    <div id="mainMenuBtn" class="switch-button-sidebar" [class.switch-button--cordova]="!showAppSwitcher" (click)="toggleDrawer()">
      <mdl-icon>menu</mdl-icon>
    </div>
  </div>

  <div class="app-logo"><img class="pointer" (click)="navigate(['/'])"
    [src]="logoUrl()"></div>
  <div class="separator"></div>
  <mdl-layout-title>
      <span *ngIf="screen === 'desktop' || selectedFilterType === taskConstants.ROUTE_TYPE_STATUS" class="app-title disable-select" [innerHtml]="environment.headerBranding"></span>
      <span *ngIf="screen !== 'desktop' && selectedFilterType === taskConstants.ROUTE_TYPE_TAG" class="app-title disable-select">{{ 'TAGS' | translate }}</span>
      <span *ngIf="screen !== 'desktop' && selectedFilterType === taskConstants.ROUTE_TYPE_LIST" class="app-title disable-select">{{ 'LISTS' | translate }}</span>
      <span *ngIf="screen !== 'desktop' && selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION" class="app-title disable-select">{{ 'LOCATIONS' | translate }}</span>
  </mdl-layout-title>
  <mdl-layout-spacer></mdl-layout-spacer>
  <!-- Search Box -->
  <div class="box search-box-holder item-gt-visibility">
    <div class="container-4">
      <input type="text" name="search" [(ngModel)]="search" (keyup)="searchTextUpdated($event.keyCode)" id="search" placeholder="{{ 'SEARCH' | translate }}"
      />
      <div class="search-box-holder__options">
          <button id="search-btn" class="mdl-button" (click)="searchTextUpdated(13)">
              <mdl-icon>search</mdl-icon>
          </button>
      </div>
    </div>
    <!-- Filter Button -->
    <div *ngIf="showAdvancedSearchPopover" (click)="hideAdvanceSearch()" class="popover-overlay"> </div>
    <div>
      <button class="advance-tune-button" (click)="showAdvancedTaskFilters()">
          <mdl-icon>tune</mdl-icon>
      </button>
    </div>
  </div>

  <mdl-layout-spacer></mdl-layout-spacer>

  <!-- Right -->
  <div class="header-drop-down">
    <!-- Show hide Search Box and Filter icon for small screen -->
    <span class="header-menu-btn item-sm-visibility">
      <button mdl-button (click)="emitAction({event: $event, action: 'task-search'})">
        <mdl-icon>search</mdl-icon>
      </button>
    </span>

    <!-- User name -->
    <span class="header-username" *ngIf="screen === 'desktop' && currentUser && currentUser.fullname">{{ currentUser.fullname }}</span>

    <!-- User menu - Profile, Logout -->
    <span class="header-menu-btn item-gt-visibility">
      <button id="header-user-profile" mdl-button #dropDownMenuBtn="mdlButton" [matMenuTriggerFor]="dropDownMenuHolder">
        <vp-avatar [avatarId]="currentUser.id" [user]="{ name: currentUser && currentUser.fullname ? currentUser.fullname : '', avatarURL: currentUser.userAvatar }"> </vp-avatar>
      </button>
      <mat-menu id="header-user-profile-menu" #dropDownMenuHolder="matMenu" class="drop-menu-nav" [overlapTrigger]="'false'">
        <div mat-menu-item (click)="openProfileDialog()">
          <span layout="row" class="drop-menu-nav__item">
            <mdl-icon>person</mdl-icon>
            {{ 'PROFILE' | translate}}
          </span>
        </div>
        <div mat-menu-item *ngIf="!isCordovaOrElectron" (click)="changePassword()">
          <span layout="row" class="drop-menu-nav__item">
            <mdl-icon>lock</mdl-icon>
            {{ 'CHANGE_PASSWORD' | translate}}
          </span>
        </div>
        <div mat-menu-item (click)="logout()">
          <span layout="row" class="drop-menu-nav__item">
            <mdl-icon>exit_to_app</mdl-icon>
            {{ 'LOGOUT' | translate }}
          </span>
        </div>
      </mat-menu>
    </span>

    <span class="header-menu-btn item-gt-visibility">
      <button id="header-setting-btn" mdl-button class="count-container" [matMenuTriggerFor]="settingsMenu" (click)="$event.isSettings = true;">
        <mdl-icon class="users-logo">settings</mdl-icon>
      </button>
    </span>

    <mat-menu #settingsMenu="matMenu" class="header-menu" [overlapTrigger]="'false'">
        <div mat-menu-item id="setting-general-settings" (click)="openGeneralSettingDialog()">
          <mdl-icon>settings</mdl-icon>
          <span>{{'TASK_GENERAL_SETTINGS' | translate}}</span>
        </div>
        <div mat-menu-item id="setting-help" (click)="openHelpFaqDialog()">
          <mdl-icon>help</mdl-icon>
          <span>{{'TASK_HELP_FAQ_TITLE' | translate}}</span>
        </div>
        <div *ngIf="currentUser && currentUser.team_user === 'true'" mat-menu-item id="users-management" [matMenuTriggerFor]="userManagementMenu">
          <mdl-icon>account_box</mdl-icon>
          <span>{{'USERS_MANAGEMENT' | translate}}</span>
        </div>
        <div mat-menu-item id="setting-service-desk" (click)="openServiceDeskDialog()">
          <mdl-icon>headset_mic</mdl-icon>
          <span>{{'TASK_SERVICE_DESK_TITLE' | translate}}</span>
        </div>
        <div mat-menu-item id="setting-changelog" (click)="openAboutDialog()">
          <mdl-icon class="bullet">format_list_bulleted</mdl-icon>
          <span>{{'VERSION_AND_CHANGELOG_LBL' | translate}}</span>
        </div>
        <div mat-menu-item id="setting-legal-notice" (click)="openLegalNoticeDialog()">
          <mdl-icon>assignment</mdl-icon>
          <span>{{'LEGAL_NOTICE' | translate}}</span>
        </div>
        <div mat-menu-item id="setting-legal-notice" (click)="openSaveSearchDialog()">
          <mdl-icon>youtube_searched_for</mdl-icon>
          <span>{{'SAVED_SEARCHES' | translate}}</span>
        </div>
    </mat-menu>
    <mat-menu #userManagementMenu="matMenu" class="header-menu" [overlapTrigger]="'false'">
        <div mat-menu-item id="invite-users" (click)="openInviteUserDialog()">
          <mdl-icon>person_add</mdl-icon>
          <span>{{ 'INVITE_USERS' | translate}}</span>
        </div>
        <div mat-menu-item id="archive-users" (click)="openArchiveUserDialog()">
          <mdl-icon>archive</mdl-icon>
          <span>{{ 'ARCHIVED_USERS' | translate}}</span>
        </div>
    </mat-menu>
  </div>
  <div class="overlay" (click)="switchApplication()" *ngIf="switchApp"></div>
</mdl-layout-header-row>
<div class="breadcrumbs-container">
  <div class="breadcrumbs">
      <div class="bread-item">
        <span>Home</span>
        <mdl-icon class="material-icons">keyboard_arrow_right</mdl-icon>
        <span>Tasks</span>
      </div>
  </div>
  <div class="refresh-holder" (click)="onClickReloadTasks()">
     <span class="icon">
      <mdl-icon class="material-icons">refresh</mdl-icon>
    </span>
    <span class="text" *ngIf="isLoading">{{ 'UPDATING' | translate }}</span>
  </div>
</div>
<vp-app-switcher [url]="url" (closeSwitch)="switchApp = false" [switchApp]="switchApp"></vp-app-switcher>
