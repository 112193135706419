
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="assign_watcher_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="onOpen.emit(id)">
  <mdl-icon>visibility</mdl-icon>
  <p>{{ 'WATCHERS' | translate }}</p>
</div>

<div id="assign-watchers-option-list" class="option-list add-watchers-list-dialog" *ngIf="active">
  <div class="add-watchers-list-header">
    <span>{{ 'WATCHERS' | translate }}:</span>
    <div class="icons-div">
      <mdl-icon *ngIf="isUpdated" class="icon-close" (click)="backToMain()">clear</mdl-icon>
      <mdl-icon *ngIf="isUpdated" class="icon-check" (click)="setWatchers()">check_circle</mdl-icon>
    </div>
  </div>
  <div class="selected-list-wrapper" *ngIf="selectedMemberList.length > 0">
    <mdl-list class="selectedMemberList">
      <mdl-list-item mdl-ripple *ngFor="let member of selectedMemberList">
        <mdl-list-item-primary-content>
          <span class="member-avatar">
            <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
            <span class="selected-member-close-icon" (click)="removeSelectedMember(member)">
              <mdl-icon>close</mdl-icon>
            </span>
          </span>
          <div class="selected-member-name" [innerHTML]="member.name"></div>
        </mdl-list-item-primary-content>
      </mdl-list-item>
    </mdl-list>
  </div>
    <mdl-list>
      <mdl-list-item
        *ngFor="let member of filteredItems; let i = index;"
        [class.selected]="i == selectedIndex"
        (click)="addMemberToList(member)">
        <vp-avatar [avatarId]="member.id" [user]="{ name: member && member.name ? member.name : '', avatarURL: member.avatar }"> </vp-avatar>
          <span class="username-place">{{(member.name !== "") ? member.name : ('NO_NAME' | translate)  }}</span>
        </mdl-list-item>
      </mdl-list>
</div>
